<template>
  <div class="full-width">
    <p-search-input
      id="tradeDirection"
      v-model="searchQuery"
      class="pt-8 pb-16"
      size="md"
      :placeholder="$t('general.search.asset')"
    />
    <div class="alarm-search-list__history">
      <transition name="fade-in">
        <div v-if="filteredHistorySearches.length > 0" class="py-8">
          <div class="place-items-between mb-8">
            <div>
              <div class="f-body-16-medium t-fg-primary" v-text="$t('general.historySearches')" />
              <div class="p-info-card__icon p-info-card__icon__success" />
            </div>
            <p-button
              class="alarm-list__button px-4"
              size="sm"
              button-type="ghost"
              block="true"
              @click="toggleOrClearHistory"
            >
              <p-icon
                v-if="!showClearConfirmation"
                icon="trash-delete"
                size="md"
                color="fg-tertiary"
              />
              <div v-else class="t-fg-tertiary" v-text="$t('general.clear')" />
            </p-button>
          </div>
          <div class="alarm-history-searches">
            <div v-for="(market, index) in filteredHistorySearches" :key="index">
              <p-chip
                class="alarm-history-searches__item"
                :label="market?.name?.toUpperCase()"
                @click="selectMarketToAlarm(market)"
              />
            </div>
          </div>
        </div>
      </transition>

      <div v-if="marketListWithAll.length > 0" class="py-8">
        <div>
          <div class="f-body-16-medium t-fg-primary" v-text="$t('general.highlights')" />
          <div class="p-info-card__icon p-info-card__icon__success" />
        </div>
        <div class="alarm-highlights place-items-between pt-12 pb-8">
          <div v-for="(market, index) in highlights" :key="index" class="place-items-center gap-24">
            <div
              class="place-items-center gap-8 p-6 cursor-pointer"
              @click="selectMarketToAlarm(market)"
            >
              <span class="f-utility-14-medium t-fg-primary" v-text="market?.name?.toUpperCase()" />
              <nuxt-img
                :src="getAsset({ asset: market?.icon ?? '', type: 'coin' })"
                width="16"
                height="16"
              />
            </div>
            <p-divider v-if="index < highlights.length - 1" :vertical="true" style="height: 32px" />
          </div>
        </div>
      </div>

      <div class="mt-16">
        <div
          v-if="listFilter && marketListWithAll.length >= 1"
          class="place-items-between mx-16 f-utility-12-medium t-fg-tertiary"
        >
          <div class="currency-list-filter__header" @click="handleSort('volume_total')">
            <p v-text="$t('market.list.volume')" />
            <p-sort :dir="sortKey === 'volume_total' ? sortOrder['volume_total'] : 'none'" />
          </div>
          <div class="place-items-center gap-8">
            <div class="currency-list-filter__header" @click="handleSort('last')">
              <p v-text="$t('market.list.lastPrice')" />
              <p-sort :dir="sortKey === 'last' ? sortOrder['last'] : 'none'" />
            </div>
            <span v-text="'/'" />
            <div class="currency-list-filter__header" @click="handleSort('change')">
              <p v-text="$t('market.change')" />
              <p-sort :dir="sortKey === 'change' ? sortOrder['change'] : 'none'" />
            </div>
          </div>
        </div>
        <div class="cursor-pointer">
          <div
            v-for="(market, index) in marketListWithAll"
            :key="index"
            @click="selectMarketToAlarm(market)"
          >
            <div class="p-menu__item">
              <div :class="['p-menu__item__inner', listFilter && 'place-items-between']">
                <div>
                  <div class="p-menu__item--title">
                    <nuxt-img
                      :src="
                        market?.id !== 'all'
                          ? getAsset({ asset: market?.icon ?? '', type: 'coin' })
                          : '/icon/all-tokens.svg'
                      "
                      width="20"
                      height="20"
                    />

                    <div>
                      <div class="place-items-start gap-4">
                        <h4 v-text="market?.fullName" />
                        <p-icon v-if="selectedMarket?.id === market.id" icon="check" size="md" />
                      </div>
                      <div>
                        <div
                          v-if="market?.id !== 'all' && !listFilter"
                          class="p-menu__item--desc"
                          v-text="market?.name?.toUpperCase()"
                        />
                        <div
                          v-else-if="listFilter"
                          class="p-menu__item--desc"
                          v-text="
                            `${numberWithSymbol({ num: market?.volume })} ${market?.pairs?.market?.toUpperCase()}`
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="listFilter"
                  class="place-items-end flex-column text-right align-items-end"
                >
                  <div class="f-utility-12-medium place-items-start gap-2">
                    <div
                      class="t-fg-primary"
                      v-text="
                        formatNumber({ num: market?.last, precision: market?.precisions?.market })
                      "
                    />
                    <div class="t-fg-tertiary" v-text="market?.pairs?.payment?.toUpperCase()" />
                  </div>
                  <div :class="`f-utility-12-medium picker__item--change-${color(market)}`">
                    <span
                      v-text="market?.percentage > 0 ? '+' : market?.percentage < 0 ? '-' : ''"
                    />
                    <span v-text="formatPercent({ num: market?.percentage })" />
                  </div>
                </div>
              </div>
            </div>
            <p-divider v-if="marketListWithAll.length - 1 !== index" class="my-2 full-width" />
          </div>
        </div>
        <empty-state
          v-if="marketListWithAll.length < 1"
          :title="
            $t('alarm.noresultSearch', {
              currency: searchQuery,
            })
          "
          icon="search-not-found"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MarketConfig } from '~/models/main'

const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  selectedMarket: {
    type: Object as () => MarketConfig | null,
    default: null,
  },
  exceptAll: {
    type: Boolean,
    default: false,
  },
  listFilter: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['changeStep', 'marketSelected'])

const mainStore = useMainStore()
const searchQuery = ref<string | number>('')
const showClearConfirmation = ref<boolean>(false)
const historySearches = ref<MarketConfig[]>([])
const currentCurrency = ref<string>('')
const currentMarket = ref<MarketConfig | null>(null)
const sortKey = ref<string>('')
const sortOrder = ref<Record<string, string>>({
  volume_total: 'desc',
  price: 'desc',
  change: 'desc',
})

const marketList = computed<MarketConfig[]>(() => {
  const markets = Object.values(mainStore.getMarketList) as MarketConfig[]

  const query = String(searchQuery.value).toLowerCase()

  const filteredMarkets = searchQuery.value
    ? markets.filter(
        (market) =>
          market.searchString?.toLowerCase().includes(query) ||
          market.name?.toLowerCase().includes(query) ||
          market.fullName?.toLowerCase().includes(query),
      )
    : markets

  const uniqueMarkets = $_.uniqBy(filteredMarkets, 'id')

  const sortedMarkets = uniqueMarkets.sort((a, b) => {
    return (a.fullName ?? '').localeCompare(b.fullName ?? '')
  })

  return sortedMarkets
})

const marketListWithAll = computed(() => {
  if (props.exceptAll) {
    return $_.chain(marketList.value)
      ?.orderBy([sortKey.value], [sortOrder.value[sortKey.value]])
      .value()
  } else {
    const query = String(searchQuery.value).toLowerCase()

    const allMarketItem: MarketConfig = {
      id: 'all',
      name: $t('general.all'),
      fullName: $t('general.allAssets'),
      icon: 'all-tokens',
      searchString: $t('general.all').toLowerCase(),
    }

    const includeAll =
      !searchQuery.value ||
      allMarketItem.searchString?.includes(query) ||
      allMarketItem.name?.toLowerCase().includes(query) ||
      allMarketItem.fullName?.toLowerCase().includes(query)

    if (includeAll) {
      return [allMarketItem, ...marketList.value]
    } else {
      return marketList.value
    }
  }
})

const highlights = computed(() => {
  return $_.orderBy(mainStore.getMarketList, ['volume_total'], ['desc']).slice(0, 4)
})

const filteredHistorySearches = computed(() => {
  if (!searchQuery.value) return historySearches.value
  const query = String(searchQuery.value).toLowerCase()
  return historySearches.value.filter(
    (market) =>
      market.searchString?.toLowerCase().includes(query) ||
      market.name?.toLowerCase().includes(query) ||
      market.fullName?.toLowerCase().includes(query),
  )
})

const loadHistoryFromLocalStorage = () => {
  const savedData = localStorage.getItem('selectCurrencySearch')
  if (savedData) {
    const markets: MarketConfig[] = JSON.parse(savedData)
    if (markets.length > 0) {
      historySearches.value = markets
    }
  }
}

const saveHistoryToLocalStorage = (markets: MarketConfig[]) => {
  localStorage.setItem('selectCurrencySearch', JSON.stringify(markets))
}

const clearSearchHistory = () => {
  localStorage.removeItem('selectCurrencySearch')
  historySearches.value = []
  showClearConfirmation.value = false
}

const toggleOrClearHistory = () => {
  if (!showClearConfirmation.value) {
    showClearConfirmation.value = true
  } else {
    clearSearchHistory()
  }
}

const selectMarketToAlarm = (market: MarketConfig) => {
  if (market.id === 'all') {
    currentCurrency.value = market.name ?? ''
    currentMarket.value = market
    emit('marketSelected', market)
    return
  }

  currentCurrency.value = market.name ?? ''

  const savedData = localStorage.getItem('selectCurrencySearch')
  const markets = savedData ? JSON.parse(savedData) : []

  if ($_.some(markets, { name: market.name, id: market.id })) {
    currentMarket.value = market
    emit('marketSelected', market)
    return
  }

  const marketWithTimestamp = $_.assign({}, market, { timestamp: Date.now() })
  const updatedMarkets = $_.orderBy([...markets, marketWithTimestamp], ['timestamp'], ['asc'])

  if (updatedMarkets.length > 10) {
    $_.pullAt(updatedMarkets, 0)
  }

  saveHistoryToLocalStorage(updatedMarkets)
  historySearches.value = updatedMarkets
  currentMarket.value = market
  emit('marketSelected', market)
}

const color = (market: MarketConfig) => {
  return market?.percentage > 0 ? 'success' : market?.percentage < 0 ? 'error' : 'primary'
}

onMounted(() => {
  loadHistoryFromLocalStorage()
})

const handleSort = (key: string): void => {
  sortKey.value = key
  sortOrder.value[key] = sortOrder.value[key] === 'asc' ? 'desc' : 'asc'
}
</script>
